<template>
    <CompModal class="qr-code-modal" ref="comp_model" title="二维码" height="auto">
        <div class="comp-qrcode-content">
            <div class="bgs-box">
                <div class="item-box" v-for="(item, idx) in bgs" :key="item" :class="{ current: bgi === idx }" :style="{ 'background-image': 'url(' + item + ')' }" @click="bgi = idx"></div>
            </div>
            <div class="code-base">
                <div class="code-box" ref="comp_qrcode_box" :style="{ 'background-image': 'url(' + bgs[bgi] + ')' }">
                    <div class="qrcode-box" :id="id"></div>
                    <img class="qrcode-img" v-if="imageUrl" :src="imageUrl" />
                    <div class="title" :style="{ top: title.length > 8 ? '150px' : '170px' }">
                        <span>{{ title }}</span>
                        <slot name="title"></slot>
                    </div>
                    <div class="community" v-if="community">{{ community }}</div>
                </div>
            </div>
        </div>
        <template #operate>
            <div class="operate-btns">
                <Button class="operate-btn" @click="onCopyLink">复制链接</Button>
                <Button class="operate-btn" type="success" style="background:#19be6b;color:#fff" @click="onDownloadQrCode">下载纯二维码</Button>
                <Button class="operate-btn highlight" type="primary" @click="onDownloadPropagandaQrCode">下载宣传二维码</Button>
            </div>
        </template>
    </CompModal>
</template>

<script>
import CompModal from "./CompModal.vue"

import html2canvas from "html2canvas"
import QRCode from "qrcodejs2"
import Utils from "../../customform/utils/utils"

export default {
    components: { CompModal },

    data() {
        return {
            id: Utils.getUuid(),
            content: null,
            title: "",
            community: "",
            bgi: 0,
            imageUrl: "",
            bgs:  [
                "/images/pic_hd1.png",
                "/images/pic_hd2.png",
                "/images/pic_hd3.png",
                "/images/pic_hd4.png",
                "/images/pic_hd5.png",
                "/images/pic_hd6.png"
            ],
        }
    },

    watch: {
        content(v) {
            this.setContent(v)
        },
    },

    mounted() {
        // 创建一个二维码控件
        this.qrcode = new QRCode(document.getElementById(this.id), {
            text: "",
            width: 1000, // 高度
            height: 1000, // 宽度
            colorDark: "#000000", // 前景色
            colorLight: "#ffffff", // 后景色
            correctLevel: QRCode.CorrectLevel.H, // 容错级别
        })

        if (this.content) {
            this.setContent(this.content)
        }
    },

    methods: {
        display(content, title, community) {
            this.content = content
            this.title = title || ""
            this.community = community

            this.$nextTick(() => {
                this.$refs.comp_model.display()
            })
        },

        // 写入二维码内容
        async setContent(content) {
            this.qrcode?.makeCode(content)

            if (!this.logo) {
                let logo = new Image()
                logo.crossOrigin = "Anonymous"
                logo.src = "/images/suiyue_logo.png"
                await new Promise(resolve => {
                    logo.onload = () => {
                        this.logo = logo
                        resolve()
                    }
                })
            }

            let container = this.$refs["comp_qrcode_box"]
            let qrImg = this.qrcode._el.getElementsByTagName("img")[0]
            let canvas = this.qrcode._el.getElementsByTagName("canvas")[0]
            let ctx = canvas.getContext("2d")
            ctx.drawImage(this.logo, 1000 * 0.5 - 450 / 2, 1000 * 0.5 - 450 / 2, 450, 450)
            qrImg.src = canvas.toDataURL()
            container.appendChild(this.qrcode._el)
            // 获取二维码图片地址
            this.imageUrl = canvas.toDataURL("image/png")
        },

        // 点击下载宣传二维码
        onDownloadPropagandaQrCode() {
            html2canvas(this.$refs.comp_qrcode_box, { useCORS: true }).then(c => {
                let src = c.toDataURL()
                var image = new Image()

                // 解决跨域 Canvas 污染问题
                image.setAttribute("crossOrigin", "anonymous")

                image.onload = () => {
                    c.width = image.width
                    c.height = image.height
                    var context = c.getContext("2d")
                    context.drawImage(image, 0, 0, image.width, image.height)
                    var url = c.toDataURL("image/png", 1.0)
                    var a = document.createElement("a")
                    var event = new MouseEvent("click")
                    a.download = this.title || "二维码"
                    a.href = url
                    a.dispatchEvent(event)
                }

                image.onerror = () => {
                    this.$Message.error("图片加载出错")
                }

                image.src = src
            })
        },

        /**
         * 下载纯二维码
         */
        onDownloadQrCode() {
            // 获取画布
            let canvas = this.$refs.comp_qrcode_box.getElementsByTagName("canvas")[0]
            let a = document.createElement("a")
            // 转换数据
            a.href = canvas.toDataURL("image/png")
            a.download = this.title || "二维码"
            a.click()
        },

        /**
         * 拷贝链接
         */
        onCopyLink() {
            // 拷贝内容
            Utils.copyText(this.content)
            // 成功提示
            this.$Message.success("复制成功")
        },
    },
}
</script>

<style lang="less">
.qr-code-modal {
    .scroll-content {
        overflow: hidden;
        display: flex;
    }
}

.comp-qrcode-content {
    display: flex;
    flex-grow: 1;

    .bgs-box {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        background: #f9f9f9;
        max-height: 610px;

        .item-box {
            width: 70px;
            height: 100px;
            margin: 10px;
            flex-shrink: 0;
            background-size: 100% 100% !important;
            box-shadow: 0 0 10px rgb(0 0 0 / 20%);
            border: 3px solid #fff;

            &.current {
                border-color: #2faaf7;
            }
        }
    }

    .code-base {
        height: 100%;
        overflow-y: auto;
        display: flex;
        justify-content: center;
        flex-grow: 1;

        .code-box {
            position: relative;
            width: 380px;
            height: 610px;
            background: url("/images/pic_hd1.jpg") no-repeat;
            background-size: cover;

            .title {
                position: absolute;
                top: 150px;
                left: 70px;
                right: 70px;
                text-align: center;
                font-size: 28px;
                font-weight: bold;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
            }

            .qrcode-box {
                position: fixed;
                right: 100%;
                bottom: 100%;
                visibility: hidden;
            }

            .qrcode-img {
                position: absolute;
                top: 270px;
                left: calc(50% - 100px);
                width: 200px;
                height: 200px;
            }

            .community {
                position: absolute;
                bottom: 65px;
                left: 50px;
                right: 50px;
                text-align: center;
                font-size: 18px;
                font-weight: bold;
                color: #666;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
