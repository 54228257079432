<template>
    <CompModal class="residentdatabase-data-modal" ref="comp_model" width="100%" :title="(isEdit ? '修改' : '新增') + ' - ' + title" @on-close="onCloseModel">
        <CompForm ref="comp_form" :forms="forms" :value="value" @on-submit="onSubmit" :data-watch="watch_form" :param="{ code: code }" :config="formConfig"></CompForm>
    </CompModal>
</template>

<script>
import CompForm from "../../customform/components/CompForm"
import CompModal from "../components/CompModal.vue"

export default {
    components: { CompForm, CompModal },

    data() {
        return {
            // 表单结构
            forms: null,
            // 表单数据
            value: null,
            // 监听表单值
            watch_form: {
                // 监听证件照类型
                idNumType: value => {
                    for (let i = 0, fs = this.forms; i < fs?.length; i++) {
                        let f = fs[i]
                        // 获取证件号码项
                        if (f.name === "idNum") {
                            // 如果为身份证类型，增加校验
                            f.verify = value == 1 ? "idNum" : null
                            break
                        }
                    }
                },
            },

            formConfig: {
                switch: {
                    open: "1",
                    close: "2",
                },
            },

            isEdit: false,
        }
    },

    props: {
        // 标题
        title: {
            type: String,
            default: "数据",
        },
        // 是否为导入表单（接口不一样）
        isImport: {
            type: Boolean,
            default: false,
        },
        // orgCode
        code: String,
        // 类型
        type: {
            type: String,
            required: false,
        },
    },

    methods: {
        async display(appId, id, value, isUbaseId = false) {
            this.id = id
            this.isEdit = !!value
            this.isUbaseId = isUbaseId

            if (appId !== this.appId) {
                await this.getForms(appId)

                this.appId = appId
            }

            this.value = value

            this.$nextTick(() => {
                this.$refs.comp_model.display()
                // 滚动到顶部
                this.$refs.comp_form.scrollTop()
            })
        },

        /**
         * 获取表单结构
         */
        async getForms(appId) {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            return this.$get("/gateway/api/fastDev/AppForm/findFormByAppId", {
                appId: appId,
            })
                .then(res => {
                    if (res.code == "200") {
                        const data = res.data

                        const forms = []

                        for (let i = 0, fps = data.formProperties; i < fps.length; i++) {
                            for (let fi = 0, fos = fps[i].formOperates; fi < fos.length; fi++) {
                                const v = fos[fi]

                                // 忽略详细地址组件，合并在 ElemAddr 组件
                                if (v.fieldType === "hidden") {
                                    continue
                                }

                                if (v.options) {
                                    v.options.forEach(o => {
                                        o.name = o.label
                                    })
                                }

                                v.max = v.limitMaxLen || (v.fieldType === "upload" ? (v.validate?.arrayType ? 9 : null) : null)

                                const type = this.getFormType(v)

                                if (type === "upload" && v.fieldType === "uploadFile") {
                                    v.resource_type = "file"
                                }

                                forms.push({
                                    ...v,
                                    serial: v.sortIndex,
                                    type: type,
                                    name: v.fieldId,
                                    title: v.fieldName,
                                    verify: v.validate?.inputValueType,
                                    multiple: v.validate?.arrayType,
                                    unit: v.describe,
                                })
                            }
                        }

                        this.forms = forms
                    }
                })
                .finally(() => {
                    this.$Message.destroy()
                })
        },

        /**
         * 获取表单项类型
         */
        getFormType(v) {
            if (v.validate?.inputValueType === "mobile") {
                return "mobile"
            }

            if (v.fieldType === "select" && v.childFormOperates?.length > 0) {
                return "radio"
            }

            if (v.fieldType === "uploadFile") {
                return "upload"
            }

            if (this.type !== "DYTX") {
                return { text: "input" }[v.fieldType] || v.fieldType
            }

            return v.fieldType
        },

        /**
         * 提交事件
         */
        onSubmit(evt) {
            const data = Object.assign({}, this.value || {}, evt.value)

            if (this.isImport) {
                this.onSubmitImport(data)
            } else {
                this.onSubmitFormData(data)
            }
        },

        /**
         * 提交导入数据更新
         */
        onSubmitImport(data) {
            const load = this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            this.$post(
                `/gateway/api/fastDev/DataImp/updateDataById?id=${this.id}`,
                { data },
                {
                    "Content-Type": "application/json",
                }
            )
                .then(res => {
                    if (res.code == 200) {
                        this.$Message.success("提交成功")
                        // 触发数据变化事件，更新表格数据
                        this.$emit("on-change")
                        // 关闭窗口
                        this.$refs.comp_model.close()
                    } else {
                        this.$Message.error(res.desc || "请求失败")
                    }
                })
                .finally(() => {
                    load()
                })
        },

        /**
         * 提交数据
         */
        onSubmitFormData(data) {
            // 处理提交数据
            // utils.eachObj(data, (k, v) => {
            //     // 空数组置空
            //     if (v instanceof Array && v.length === 0) {
            //         data[k] = null
            //     }
            // })

            if (this.isUbaseId) {
                return this.$post(`/syaa/api/syuser/pc/uaUserInfo/updateUserById?ubaseId=${this.id}`, { data }, { "Content-Type": "application/json" }).then(res => {
                    if (res.code == 200) {
                        this.$Message.success("保存成功")
                        // 触发数据变化事件，更新表格数据
                        this.$emit("on-change")
                        // 关闭窗口
                        this.$refs.comp_model.close()
                    } else this.$Message.error(res.desc || "保存失败")
                })
            }

            var url

            if (this.value) {
                url = this.type === "DYTX" ? "update?formDataId=" + this.id : `updateData/${this.id}`
            } else {
                url = this.type === "DYTX" ? `create?entrance=1&appId=${this.appId}` : `addData/${this.appId}?entrance=1&orgCode=${this.code}`
            }

            this.$post(
                `/gateway/api/fastDev/AppData/${url}`,
                { data },
                {
                    "Content-Type": "application/json",
                }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success("提交成功")
                    // 关闭窗口
                    this.$refs.comp_model.close()
                    // 触发数据变化事件，更新表格数据
                    this.$emit("on-change")
                } else {
                    this.$Message.error(res.desc || "保存失败")
                }
            })
        },

        /**
         * 监听模态框关闭事件
         */
        onCloseModel() {
            // 置空表单数据
            this.value = null
            // 置空表单组件
            this.$refs.comp_form.setEmpty()
        },
    },
}
</script>

<style lang="less">
.residentdatabase-data-modal {
    .content-page {
        max-width: 1500px !important;

        .form-box {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;

            .item-box {
                width: calc(33.33%);
                max-width: initial;

                @media (min-width: 1000px) {
                    padding: 0 20px;
                }

                @media (max-width: 1000px) {
                    width: calc(50%);
                }

                @media (max-width: 600px) {
                    width: calc(100%);
                }
            }
        }
    }
}
</style>
